@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 185 40% 12%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    /* rgb(241, 245, 249) */
    --secondary-foreground: 222.2 47.4% 11.2%;
    /* rgb(15, 23, 42) */
    --active: 210 40% 96.1%;
    /* rgb(241, 245, 249) */
    --secondary-foreground: 222.2 47.4% 11.2%;
    /* rgb(15, 23, 42) */
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground min-h-screen bg-[#F0F4FC] text-[#12282A];
    font-family: "system-ui", sans-serif;
    overflow-x: clip;
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    padding: 0;
    margin: 0;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
  /* Customize the track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  /* Customize the thumb color */
  border-radius: 10px;
  /* Optional: rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Customize the thumb hover color */
}

.custom-gradient {
  background: linear-gradient(90deg, #0f2a2c 0%, #c0de60 49.5%, #0f2a2c 100%);
  /* border-image-source: linear-gradient(90deg, #0F2A2C 0%, #C0DE60 49.5%, #0F2A2C 100%); */
}

.lds-ripple {
  /* change color here */
  color: #c0de60;
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

.settingsEditorWrapper {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #cecece;
  min-height: 14rem;
  resize: vertical;
}

.settingsEditorContent {
  padding: 0 16px;
  /* Adjust the padding as needed */
  min-height: 8rem;
  height: 100%;
}

.settingsEditorContent h1 {
  font-size: 2rem;
}

.settingsEditorToolbar {
  display: none !important;
}

.rdw-list-dropdown, .rdw-text-align-dropdown {
  z-index: 0 !important;
}

.flatrate-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border: 2px solid transparent;
  background: linear-gradient(to bottom left, #5FD5DE, #C0DE60) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  border-radius: inherit;
  overflow: hidden;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: grey;
  width: 16px;
  height: 16px;
  border: 1px solid grey;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]:checked::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: linear-gradient(300deg, #5FD5DE 0%, #C0DE60 100%);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}